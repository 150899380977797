
import { _Client } from "@/api-client";
import { onMounted, reactive, ref, SetupContext, toRefs } from "vue";
import { AddRoleCmd } from "@/api-client/client";
import { Form } from "ant-design-vue";

const useForm = Form.useForm;
export default {
  setup(props: {}, context: SetupContext) {
    const formStyle = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
    const modalVisible = ref<boolean>(false);
    const saveLoading = ref<boolean>(false);

    const assignRegion = ref<boolean>(true);
    const regionListData = ref<any>([]);
    const accessTreeData = ref<any>([]);
    const accessExpandedKeys = ref<any>([]);

    const formState = reactive<{
      createRoleValue: AddRoleCmd;
      createRoleRules: any;
    }>({
      createRoleValue: AddRoleCmd.fromJS({
        name: "",
        regionIdList: [],
        menuIdList: [],
      }),
      createRoleRules: {
        name: [
          {
            required: true,
            message: "Please input role name",
            trigger: "blur",
          },
        ],
        regionIdList: [
          {
            required: true,
            message: "Please select region",
            trigger: "blur",
          },
        ],
        menuIdList: [
          {
            type: "array",
            required: true,
            message: "Please select access",
            trigger: "blur",
          },
        ],
      },
    });

    const formItem = useForm(formState.createRoleValue, formState.createRoleRules);

    async function getRegionList() {
      regionListData.value = await _Client.regionClient.getAll(false);
    }

    async function getAccessList() {
      var trees = await _Client.menuClient.menuTreeQuery(assignRegion.value);
      var pages: any = [];
      var expandedPages: any = [];
      trees.forEach((page) => {
        var pageItem = {
          title: page.name,
          key: page.id,
          children: [],
        };
        var children: any = [];
        page.menuTreeChildren?.forEach((btn) => {
          children.push({ title: btn.name, key: btn.id });
        });
        pageItem.children = children;
        expandedPages.push(page.id);
        pages.push(pageItem);
      });
      accessExpandedKeys.value = expandedPages;
      accessTreeData.value = pages;
    }

    async function assignRegionChange() {
      formState.createRoleValue.menuIdList = [];
      formState.createRoleValue.regionIdList = [];
      await getAccessList();
    }

    function accessCheck(selectedKeys: any, e: any) {
      formState.createRoleValue.menuIdList = selectedKeys;
    }

    function onExpand(expandedKeys: any) {
      // 更新 expanded-keys 的状态
      accessExpandedKeys.value = expandedKeys;
    }

    async function showModal() {
      modalVisible.value = true;
      await getAccessList();
      await getRegionList();
    }

    function hideModal() {
      modalVisible.value = false;
    }

    async function saveRole() {
      if (assignRegion.value) {
        await formItem.validate();
      } else {
        formState.createRoleValue.regionIdList = <any>null;
        await formItem.validate(["name", "menuIdList"]);
      }
      saveLoading.value = true;
      try {
        let item = { ...formState.createRoleValue };
        item.menuIdList = item.menuIdList?.filter(
          (x) => accessTreeData.value.filter((b: any) => b.key == x).length === 0
        );

        await _Client.roleClient.addRole(item);
      
        context.emit("success");
        hideModal();
      } finally {
        saveLoading.value = false;
      }
    }

    return {
      formStyle,
      accessTreeData,
      accessExpandedKeys,
      regionListData,
      ...toRefs(formState),
      assignRegion,
      modalVisible,
      saveLoading,
      validateInfos: formItem.validateInfos,
      resetFields: formItem.resetFields,
      showModal,
      hideModal,
      saveRole,
      accessCheck,
      assignRegionChange,
      onExpand,
    };
  },
};
