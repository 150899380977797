
import Vue, { reactive, ref, toRefs } from "vue";
import { ModifyRoleCmd, SearchRoleRep } from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form } from 'ant-design-vue';

const useForm=Form.useForm;
export default {
  setup() {
    const formStyle = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
    const modalVisible = ref<boolean>(false);
    const saveLoading = ref<boolean>(false);

    const editDetail = ref<SearchRoleRep>(SearchRoleRep.fromJS({}));

    const formState = reactive<{modifyReq: ModifyRoleCmd; modifyRules: any;}>
    ({
      modifyReq: ModifyRoleCmd.fromJS({ roleId: "", roleTreeIdList: [] }),
      modifyRules: {
        roleTreeIdList: [
          {
            type: "array",
            required: true,
            message: "Please select access",
            trigger: "blur",
          },
        ],
      },
    });

    const formItem = useForm(formState.modifyReq, formState.modifyRules);

    const accessTreeData = ref<any>([]);
    const accessExpandedKeys = ref<any>([]);

    async function getAccessList(assignRegion: boolean) {
      var trees = await _Client.menuClient.menuTreeQuery(assignRegion);
      var pages: any = [];
      var expandedPages: any = [];
      trees.forEach((page) => {
        var pageItem = { title: page.name,key: page.id,children: [] };
        var children: any = [];
        page.menuTreeChildren?.forEach((btn) => {
          children.push({ title: btn.name, key: btn.id });
        });
        pageItem.children = children;
        expandedPages.push(page.id);
        pages.push(pageItem);
      });
      accessExpandedKeys.value = expandedPages;
      accessTreeData.value = pages;
    }

    async function getRoleAccess(id: string) {
      var checktrees = await _Client.roleClient.getRoleById(id);
      formState.modifyReq.roleTreeIdList = checktrees;
    }

    async function showModal(params: SearchRoleRep) {
      editDetail.value = params;
      formState.modifyReq.roleId = params.id;
      modalVisible.value = true;
      await getAccessList(params.region ? true : false);
      await getRoleAccess(params.id);
    }

    function hideModal() {
      modalVisible.value = false;
    }

    async function saveRole() {
      await formItem.validate();
      saveLoading.value = true;
      try {
        let item = {...formState.modifyReq};
        item.roleTreeIdList=item.roleTreeIdList?.filter(x=>accessTreeData.value.filter((b:any)=>b.key==x).length===0);
        await _Client.roleClient.modify(item);
        hideModal();
      } finally {
        saveLoading.value = false;
      }
    }

    function accessCheck(selectedKeys: any, e: any) {
      formState.modifyReq.roleTreeIdList = selectedKeys;
    }

    function onExpand(expandedKeys: any) {
     // 更新 expanded-keys 的状态
     accessExpandedKeys.value = expandedKeys;
    }

    return {
      editDetail,
      ...toRefs(formState),
      modalVisible,
      formStyle,
      accessTreeData,
      accessExpandedKeys,
      accessCheck,
      showModal,
      hideModal,
      validateInfos: formItem.validateInfos,
      resetFields: formItem.resetFields,
      saveRole,
      saveLoading,
      onExpand,
    };
  },
};
