import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { UndoOutlined } from "@ant-design/icons-vue";
import { PagedResultOfSearchRoleRep, SearchRoleQuery, SearchRoleRep } from '@/api-client/client'
import { _Client } from '@/api-client';
import Pagination from '@/components/Pagination/index.vue'
import moment from "moment-timezone"
import AddRole from './components/AddRole.vue'
import EditRole from './components/EditRole.vue'
export default defineComponent({
  components: {
    UndoOutlined,
    Pagination,
    AddRole,
    EditRole
  },
  setup() {
    const timeMoment = moment
    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Region',
        key: 'region',
        slots: { customRender: 'region' }
      },
      {
        title: 'Operation',
        key: 'operation',
        slots: { customRender: 'operation' }
      }
    ]

    const state = reactive({
      searchParames: new SearchRoleQuery(),
      tableSource: new PagedResultOfSearchRoleRep(),
      tableLoading: false
    })

    onMounted(async () => {
      await searchList()
    })

    async function searchList() {
      state.searchParames.pageIndex = 1;
      await searchRole()
    }

    async function searchRole() {
      state.tableLoading = true;
      state.tableSource = await _Client.roleClient.searchRole(state.searchParames)
      state.tableLoading = false;
    }

    async function reset() {
      state.searchParames.roleName = "";
      state.searchParames.pageIndex = 1;
      await searchRole();
    }

    async function deleteRole(params: SearchRoleRep) {
      await _Client.roleClient.deleteRole(params.id)
      await searchRole()
    }

    function AddOrModifyOperateion() {
      const addRoleRef = ref(null)
      const editRoleRef = ref(null)

      function addRole() {
        (addRoleRef.value as any).showModal();
      }

      function editRole(params: SearchRoleRep) {
        (editRoleRef.value as any).showModal(params)
      }

      return { addRoleRef, editRoleRef, addRole, editRole }
    }

    return {
      timeMoment,
      tableColumns,
      ...toRefs(state),
      searchList,
      searchRole,
      reset,
      deleteRole,
      ...AddOrModifyOperateion()
    }
  }
})
